import {
    AuthenticateGuard,
    ButtonModule,
    HandlerErrorService,
    LocalizationResolver,
    ModalsModule,
    RouteLocalizationConfig,
    SosAuthorizationStoreModule,
    SystemIconModule,
} from '@serviceos-ng/core';
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { Route, RouterModule, UrlSegment } from '@angular/router';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CoreConfigGuard,
    ThemeSwitchGuard,
    SosCoreModule,
} from '@serviceos-ng/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { AccountsHandlerErrorService } from './accounts-handler-error.service';
import { LoginAndRegisterComponent } from 'libs/domains/accounts/root/src/lib/pages/login-and-register/login-and-register.component';
import { PageNotFoundComponent } from 'libs/domains/accounts/root/src/lib/pages/page-not-found/page-not-found.component';

// import { languages } from 'countries-list';


const config = (window as any).config;

// const themes = {
// themeSwitchCases: {
//   one: () =>
//     import(
//       './../../../../libs/domains/accounts/root-one/src/lib/domain-accounts-root.module'
//     ).then((m) => m.DomainAccountsRootModule),
// },
// };

const appRoutes: Route[] = [
    {
        path: 'auth',
        loadChildren: () =>
            import(
                '@serviceos-ng/screens/authentication-screens/authentication-screens.module'
            ).then((m) => m.AuthenticationScreensModule)
    },
    {
        path: 'create-account',
        redirectTo: 'auth/create-account'
    },
    {
        path: 'recover-password/:id',
        redirectTo: 'auth/recover-password/:id'
    },
    {
        path: '',
        loadChildren: () =>
            import(
                './../../../../libs/domains/accounts/root/src/lib/domain-accounts-root.module'
            ).then((m) => m.DomainAccountsRootModule),
        canLoad: [AuthenticateGuard],
        // resolve: [LocalizationResolver],
        // canActivate: [AuthenticateGuard],
        runGuardsAndResolvers: 'always',
        data: {
            themeSwitchCases: {},
            // routeLocalizationConfig: new RouteLocalizationConfig({
            //     key: 'app',
            //     path: 'lib/domain-accounts-root',
            //     modules: ['application', 'customer', 'chat'],
            // }),
        },
    }
    
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {
            initialNavigation: 'enabledNonBlocking',
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled',
            // enableTracing: !environment.production,
        }),
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false,
            },
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        SosCoreModule.forRoot(config, environment),
        ButtonModule,
        SystemIconModule,
        ModalsModule,
        SosAuthorizationStoreModule
    ],
    providers: [
        {
            provide: HandlerErrorService,
            useClass: AccountsHandlerErrorService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
